<template>
<span>
    <v-toolbar flat color="white">
        <v-toolbar-title class="lime--text lighten-1">Gerenciar Categorias</v-toolbar-title>
        <v-divider class="mx-2" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <template>
            <v-btn color="blue-grey darken-2" dark class="mb-1" @click.prevent="atualizaDados">
                <v-icon dark>sync</v-icon>Atualizar
            </v-btn>
            <v-btn color="success" dark class="mb-1 ml-2" :to="{name: 'product-category.add'}">
                <v-icon class="mdi mdi-plus"></v-icon> Nova Categoria
            </v-btn>
        </template>
    </v-toolbar>

    <v-card>
        <v-card-title>
            <v-text-field v-model="search" append-icon="search" label="Localizar" single-line hide-details></v-text-field>
        </v-card-title>
        <v-data-table :headers="headers" :items="loadProductCategories" :search="search" :rowsPerPageItems="rowsPerPageItems">

            <template v-slot:[`item.prod_c_icone`]="{ item }">
                <td width="15%" v-if="item.prod_c_icone"><img :src="`${base_url}upload/product_category/i/${item.prod_c_icone}?${uuid}`" class="avatar"></td>
                <td width="15%" v-if="!item.prod_c_icone"><img :src="`${base_url}upload/imagem.png`" class="avatar"></td>
            </template>

            <template v-slot:[`item.prod_c_banner`]="{ item }">
                <td width="15%" v-if="item.prod_c_banner"><img :src="`${base_url}upload/product_category/b/g/${item.prod_c_banner}?${uuid}`" class="avatar"></td>
                <td width="15%" v-if="!item.prod_c_banner"><img :src="`${base_url}upload/imagem.png`" class="avatar"></td>
            </template>

            <template v-slot:[`item.prod_c_status`]="{ item }">
                <td>
                    <v-switch color="lime" v-model="item.prod_c_status" @change="onChangeEventHandlerStatus(item.prod_c_id, $event)" class="my-switch"></v-switch>
                </td>
            </template>

            <template v-slot:[`item.prod_c_show_menu`]="{ item }">
                <td>
                    <v-switch color="lime" v-model="item.prod_c_show_menu" @change="onChangeEventHandlerShowMenu(item.prod_c_id, $event)" class="my-switch"></v-switch>
                </td>
            </template>

            <template v-slot:[`item.acoes`]="{ item }">
                <v-tooltip top color="blue white--text">
                    <template v-slot:activator="{ on }">
                        <v-btn icon color="blue lighten-1" @click.prevent="editProductCategory(item)" v-on="on">
                            <v-icon dark>mdi-pencil</v-icon>
                        </v-btn>
                    </template>
                    <span>Editar</span>
                </v-tooltip>

                <v-tooltip top color="red white--text">
                    <template v-slot:activator="{ on }">
                        <v-btn icon color="red lighten-1" @click.prevent="deleteProductCategory(item)" v-on="on">
                            <v-icon dark class="mdi mdi-delete"></v-icon>
                        </v-btn>
                    </template>
                    <span>Excluir</span>
                </v-tooltip>

                <v-tooltip top color="lime black--text">
                    <template v-slot:activator="{ on }">
                        <v-btn icon color="lime lighten-1" @click.prevent="detailProductCategory(item)" v-on="on">
                            <v-icon dark class="mdi mdi-reorder-horizontal"></v-icon>
                        </v-btn>
                    </template>
                    <span>Detalhes</span>
                </v-tooltip>
            </template>

            <template v-slot:no-results>
                <v-alert :value="true" color="error" icon="warning">Voce procurou por "{{ search }}" nenhum item localizado.</v-alert>
            </template>
        </v-data-table>
    </v-card>
</span>
</template>

<script>
import {
    URL_BASE
} from "../../../config/configs";

export default {
    name: "ProductCategoryComponent",
    created() {
        this.$store.dispatch("loadProductCategories");
    },
    computed: {
        loadProductCategories() {
            if (this.$store.state.productcategories.items.data == null) return [];

            return this.$store.state.productcategories.items.data;
        }
    },
    data() {
        return {
            search: "",
            rowsPerPageItems: [
                10,
                20,
                30,
                50,
                {
                    text: "$vuetify.dataIterator.rowsPerPageAll",
                    value: -1
                }
            ],
            headers: [{
                    text: "Icone",
                    align: "left",
                    sortable: false,
                    value: "prod_c_icone"
                },
                {
                    text: "Banner",
                    align: "left",
                    sortable: false,
                    value: "prod_c_banner"
                },
                {
                    sortable: false,
                    text: "Nome",
                    value: "prod_c_nome"
                },
                {
                    sortable: false,
                    text: "Código",
                    value: "prod_c_codigo"
                },
                {
                    sortable: false,
                    text: "Ordem",
                    value: "prod_c_ordem"
                },
                {
                    sortable: false,
                    text: "Departamento",
                    value: "departamento.prod_dp_nome"
                },
                {
                    sortable: false,
                    text: "Status",
                    value: "prod_c_status"
                },
                {
                    sortable: false,
                    text: "Mostrar Menu",
                    value: "prod_c_show_menu"
                },
                {
                    text: "Ações",
                    align: "center",
                    sortable: false,
                    value: "acoes"
                }
            ],
            productcategory: [],
            base_url: URL_BASE
        };
    },
    methods: {
        atualizaDados() {
            this.$store.dispatch("loadProductCategories");
        },
        editProductCategory(item) {
            this.$router.push({
                name: "product-category.edit",
                params: {
                    prod_c_id: item.prod_c_id
                }
            });
        },
        detailProductCategory(item) {
            this.$router.push({
                name: "product-category.detail",
                params: {
                    prod_c_id: item.prod_c_id
                }
            });
        },
        deleteProductCategory(item) {
            this.$swal({
                title: "Você tem certeza?",
                text: "Você não poderá reverter isso",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#D32F2F",
                cancelButtonColor: "#1976D2",
                confirmButtonText: "Sim, Exclua!"
            }).then(result => {
                if (result.value) {
                    this.$store
                        .dispatch("destroyProductCategory", item.prod_c_prod_c_id)
                        .then(response => {
                            if (response) {
                                this.$swal({
                                    position: "center",
                                    icon: "success",
                                    title: "Sucesso",
                                    text: "Dados excluídos com sucesso",
                                    showConfirmButton: true,
                                    timer: 6000
                                });
                                this.atualizaDados();
                            } else {
                                this.$swal({
                                    position: "center",
                                    icon: "error",
                                    title: "Opssss!",
                                    text: "Erro ao excluir dados",
                                    showConfirmButton: true,
                                    timer: 6000
                                });
                            }
                        })
                        .catch(error => {
                            this.$swal({
                                position: "center",
                                icon: "error",
                                title: "Opssss!",
                                text: "Algo errado aconteceu!",
                                showConfirmButton: true,
                                timer: 6000
                            });
                        });
                }
            });
        },
        onChangeEventHandlerStatus(prod_c_id, value) {
            this.$store
                .dispatch("updateProductCategoryStatus", {
                    prod_c_id: prod_c_id,
                    prod_c_status: value
                })
                .then(() => {
                    this.$swal({
                        position: "center",
                        icon: "success",
                        title: "Sucesso",
                        text: "Status atualizado com sucesso",
                        showConfirmButton: true,
                        timer: 6000
                    });
                })
                .catch(error => {
                    this.$swal({
                        position: "center",
                        icon: "error",
                        title: "Opssss!",
                        text: "Erro ao atualizar Status",
                        showConfirmButton: true,
                        timer: 6000
                    });
                });
        },
        onChangeEventHandlerShowMenu(prod_c_id, value) {
            this.$store
                .dispatch("updateProductCategoryShowMenu", {
                    prod_c_id: prod_c_id,
                    prod_c_show_menu: value
                })
                .then(() => {
                    this.$swal({
                        position: "center",
                        icon: "success",
                        title: "Sucesso",
                        text: "Mostrar menu atualizado com sucesso",
                        showConfirmButton: true,
                        timer: 6000
                    });
                })
                .catch(error => {
                    this.$swal({
                        position: "center",
                        icon: "error",
                        title: "Opssss!",
                        text: "Erro ao atualizar mostrar menu",
                        showConfirmButton: true,
                        timer: 6000
                    });
                });
        }
    },
    components: {}
};
</script>

<style scoped>
.avatar {
    height: 40px;
    margin-top: 0.4rem;
}
</style>
